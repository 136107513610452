import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
// import { createStore, compose, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { createBrowserHistory } from 'history';
// import { Provider } from 'react-redux';
// import reducer from './reducers';

// import './index.css';
// Este es el index de nuestra app, aqui mandamos a llamar los componentes

//  importamos nuestro enrutador 
import App from './components/App'

import * as serviceWorker from './serviceWorker';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const preloadedState = window.__PRELOADED_STATE__;
// const store = createStore(reducer, preloadedState, composeEnhancers(applyMiddleware(thunk)));
// const history = createBrowserHistory();

// delete window.__PRELOADED_STATE__;

// Lo agregamos aqui para que sea visible
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
