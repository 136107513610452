import React from 'react'
import '../assets/styles/Login.scss'

import axios from 'axios'
import swal from 'sweetalert';

// IMAGENES
import logo_transparente from '../assets/imagenes/akryp_logo.png'
import info from '../assets/imagenes/info.png'

import url from '../utils/config.js'

// Aqui mero mandamos a llamar los demas componentes 
class Login extends React.Component {
    constructor(props, context){
        super(props, context)

        this.state = {
            loading: false,
            email: '',
            language: '',
            id_user: '',
        }
    }

    // Funcion para manejar cambios en los valores del formulario
    changeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }  

    LoginFunction = async() => {
        this.setState({loading: true});

        const response = await  fetch(
            axios.post(url.url+'/api/users/register_login/', this.state) //--->SERVERSITE
            .then(response => {
                    console.log("OK");
                    document.cookie = `email=${this.state.email}`;
                    window.location.href = "Simulator/"
                })   
            .catch(res_code => {
                swal("ERROR EN EL LOGIN!", {
                    icon: "error", buttons: false, timer: 3000, }); 

                this.setState({loading: false});
            })
        )
    }

    // Funcion para hacer login
    submitHandler = (event) => {
        event.preventDefault()   
        
        this.LoginFunction();
    }

    render() {

    return (
        <div className="App">

            <div className="row header_login">
                <img src={logo_transparente} className="Logo_Login" alt="logo" />

                <span className="simulator_text">Simulador</span>
            </div>

            <br />
            <br />


            <div className="grid">
                <div className="register">
                    <h2 className='title_login'>Login</h2>

                    <div className='div_75'>
                        <img src={info} className="info_logo" alt="info" />
                        <span className='text_this_only_simulator'> Esto es solo para guardar tu información en el <b className='texto_Azul'>simulador</b> no necesitas registrarte.</span>
                    </div>


                    <br />
                    <br />
                    <br />

                    <form action="" method="post" className="form" onSubmit={this.submitHandler}>
                        <div className="form__field">
                            <input type="email" required placeholder="name@mailaddress.com" name="email" onChange={this.changeHandler} />
                        </div>

                        <div className="form__field">
                            <button className="btn_submit">
                                <span>Entrar</span>
                            </button>
                        </div>
                    </form>

                    <div>
                        <img src={info} className="info_logo" alt="info" />
                        <span className='text_without_pass'>¿Por qué sin contraseña?</span>
                    </div>
                    <br />
                    <span className='text_what_akrypsimulator'>Este es el <b className='texto_Azul'>Simulador de Akryp</b>, el propósito de este simulador es mostrarte como funciona nuestro algoritmo.
                            Usamos información real pero con dinero ficticio, es por eso que solo pedimos tu email, con eso podrás acceder a tu "dinero falso" y ver
                            como tu inversión crece sin ningún riesgo.
                    </span>
                </div>
            </div>
        </div>
    )
}

}
export default Login