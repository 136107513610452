import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './Home'
import Login from './LoginLandingPage'
import Simulator from './SimulatorHome'


function getCookie(name) {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
  }
  else
  {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
      end = dc.length;
      }
  }
  // because unescape has been deprecated, replaced with decodeURI
  //return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
} 


// Aqui es donde definimos las rutas
function App() {

  var cookie_email;

  if(getCookie("email") != null )
  {
    // console.log(getCookie("id_user"));
    cookie_email = document.cookie.split('; ').find(row => row.startsWith('email')).split('=')[1];

    // console.log(cookie_email);
  }

  return (
    <BrowserRouter>
      <Switch>  
        <Route exact path="/" component={Home} />
        <Route exact path="/Login" component={Login} />
        { cookie_email === undefined && <Route component={Login} />}
        { cookie_email != undefined && <Route exact path="/Simulator" component={Simulator} />}         
        { cookie_email != undefined && <Route exact path="/Simulator/Simulator" component={Simulator} />}   
      </Switch>
    </BrowserRouter>
  );
}

export default App;