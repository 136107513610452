import React from 'react'
import '../assets/styles/Login.scss'
import '../assets/styles/Simulator.css'

import axios from 'axios'
import swal from 'sweetalert';

// IMAGENES
import logo_transparente from '../assets/imagenes/akryp_logo.png'
import info from '../assets/imagenes/info.png'
import plus from '../assets/imagenes/plus2.png'

import url from '../utils/config.js'

// Aqui mero mandamos a llamar los demas componentes 
class SimulatorHome extends React.Component {
    constructor(props, context){
        super(props, context)

        this.state = {
            loading: false,
            email: document.cookie.split('; ').find(row => row.startsWith('email')).split('=')[1],
            language: '',
            id_user: 0,
            money_total: 0.00,
            porcentaje_ganado: '+ 0%',
            money_invertido_user: 0.00,
            money_ganado: 0.00,
            total_transactions: 0,
            successful_transactions: 0,
            fail_transactions: 0,
            successful_transactions_porcentaje: 0,
            fail_transactions_porcentaje: 0,
            money_invertir: '',
            statusInvestment: 1 // 0 es para detenerlo
        }
    }

    componentDidMount() {
        this.getStats();
    }

    // Funcion para manejar cambios en los valores del formulario
    changeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }  

    getStats = async() => {
        this.setState({loading: true});

        const resp = await  fetch(
            axios.post(url.url+'/api/users/stats_user/', this.state) //--->SERVERSITE
            .then(resp => {
                    // console.log(resp.data.data)
                    this.setState({id_user: resp.data.data[0].id_user, 
                                    money_total: resp.data.data[0].money_total,
                                    porcentaje_ganado: resp.data.data[0].porcentaje_ganado,
                                    money_invertido_user: resp.data.data[0].money_invertido_user,
                                    money_ganado: resp.data.data[0].money_ganado,
                                    total_transactions: resp.data.data[0].total_transactions,
                                    successful_transactions: resp.data.data[0].successful_transactions,
                                    fail_transactions: resp.data.data[0].fail_transactions,
                                    successful_transactions_porcentaje: resp.data.data[0].successful_transactions_porcentaje,
                                    fail_transactions_porcentaje: resp.data.data[0].fail_transactions_porcentaje,
                                    loading: false,
                                    money_invertir: ''
                                })
                })   
            .catch(res_code => {
                swal("ERROR AL OBTENER ESTADISTICAS!", {
                    icon: "error", buttons: false, timer: 3000, }); 

                this.setState({loading: false});
            })
        )
    }

    // Funcion para aregar dinero ficticio
    Add_money_ficticio = (event) => {
        this.setState({loading: true});

        try{
            if(this.state.money_invertir > 0)
            {
                axios.post(url.url+'/api/users/register_invest/', this.state) //--->SERVERSITE
                .then(resp => {
                    console.log("REGISTRADO CON EXITO");

                    this.Ejecutar_Bot();
                    // this.getStats();
                })   
                .catch(res_code => {
                    swal("ERROR AL REGISTRAR DINERO!!", {
                        icon: "error", buttons: false, timer: 3000, }); 

                    this.setState({loading: false});
                })
            }   
            else
            {
                swal("DEBE INGRESAR UNA CANTIDAD MAYOR A 0!!", {
                    icon: "error", buttons: false, timer: 3000, });  
            }
        }
        catch{
            swal("DEBE INGRESAR UNA CANTIDAD CORRECTA!!", {
                icon: "error", buttons: false, timer: 3000, }); 
        }
    }

    // LLAMAMOS A LA RUTA DEL SERVERSITE PARA QUE EMPIECE A INVERTIR EN BITCOIN
    Ejecutar_Bot = (event) => {
        this.setState({loading: true});

        try{
            axios.post(url.url+'/api/simulations/bot/bitcoin/', this.state) //--->SERVERSITE
            .then(resp => {
                console.log("BOT INICIA");

                this.getStats();
            })   
            .catch(res_code => {
                swal("ERROR AL REGISTRAR DINERO!!", {
                    icon: "error", buttons: false, timer: 3000, }); 

                this.setState({loading: false});
            })
        }
        catch{
            swal("DEBE INGRESAR UNA CANTIDAD CORRECTA!!", {
                icon: "error", buttons: false, timer: 3000, }); 
        }
    }

    render() {

    return (
        <div className="App">

            <div className='row'>
                <img src={logo_transparente} className="Logo_Simulator" alt="logo" />

                <div className='padding_simulator_title'>
                    <span className="text_simulator_home">Simulador</span>
                </div>
            </div>

            <div className='row row_info_simulator_fake'>
                <img src={info} className="info_logo" alt="info" />
                <span className='text_info_simulator_fake padding_info_simulator_fake'> Esto es solo un <b className='texto_Azul'>simulador</b>,  aquí podrás hacer pruebas y ver como funciona nuestro algoritmo con datos reales, en tiempo real. Todo el dinero invertido es falso.</span>
            </div>

            <br />
            <br />
            <br />
            <br />

            <div className='div_centrado'>
                <div>
                    <img src={info} className="info_logo" alt="info" />
                    <span className='text_fake_money'> Agrega el dinero ficticio que quieras invertir.</span>
                </div>

                <div className='padding_text_fake_money_2'>
                    <span className='text_fake_money'>Regresa en unos días y mira como tu dinero ha crecido con nuestro algoritmo.</span>
                </div>
            </div>

            <br />

            <div className='grid balance_fake_money'>
                <div className='div_padding_balance'></div>
                <span className='text_balance_fake_money'> Balance del dinero ficticio</span>

                <div className='div_padding_balance'></div>

                <div className='div_ganancias_user div_centrado'>
                    <span className='text_money_total'>${this.state.money_total}</span><span className='text_moneda'> USD</span>
                    {this.state.porcentaje_ganado >= 0 && <span className='text_porcentaje_ganacia'> +{this.state.porcentaje_ganado}%</span>}
                    {this.state.porcentaje_ganado < 0 && <span className='text_porcentaje_ganacia_negativo'> {this.state.porcentaje_ganado}%</span>}
                </div>

                <div className='div_padding_balance'></div>

                <div className='text_invertido_ganado'>
                    <span>Invertido: ${this.state.money_invertido_user}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span >Ganancia: ${this.state.money_ganado}</span>
                </div>
            </div>

            <br />

            <div className='grid'>
                <div className='div_centrado'>
                    <input type="text" maxLength="8" placeholder="$0.00" name="money_invertir" value={this.state.money_invertir} className='input_money' onChange={this.changeHandler} />
                    <button className="btn_add_fake_money" onClick={this.Add_money_ficticio}><img src={plus} className="plus_logo" alt="add" /> <span className='padding_text_botton'> Agregar dinero ficticio</span></button>
                </div>
            </div>

            <br />

            <div className='grid grid_stats'>
                <div className='div_padding_balance'></div>
                <span className='text_balance_fake_money'> Estadísticas</span>

                <div className='div_padding_balance'></div>

                <div className='div_centrado'>
                    <div>
                        <img src={info} className="info_logo" alt="info" />
                        <span className='text_fake_money'> Aquí tienes algunas estadísticas de nuestro algoritmo mientras invierte tu </span>
                    </div>

                    <div className='padding_text_fake_money_2'>
                        <span className='text_fake_money'>dinero ficticio. Se actualiza diariamente.</span>
                    </div>
                </div>

                <div className='div_padding_balance'></div>
                <div className='div_padding_balance'></div>

                <div className='row div_stat'>
                    <span className='stats_titles'>Transacciones totales: <span className='text_stats'>{this.state.total_transactions}</span></span>
                </div>

                <div className='div_padding_balance'></div>

                <div className='row div_stat'>
                    <span className='stats_titles'>Transacciones exitosas totales: <span className='text_stats'>{this.state.successful_transactions}</span></span>
                </div>

                <div className='div_padding_balance'></div>

                <div className='row div_stat'>
                    <span className='stats_titles'>Transacciones falladas totales: <span className='text_stats'>{this.state.fail_transactions}</span></span>
                </div>

                <div className='div_padding_balance'></div>

                <div className='row div_stat'>
                    <span className='stats_titles'>% Transacciones exitosas totales: <span className='text_stats'> {this.state.successful_transactions_porcentaje}%</span></span>
                </div>

                <div className='div_padding_balance'></div>

                <div className='row div_stat'>
                    <span className='stats_titles'>% Transacciones falladas totales: <span className='text_stats'>{this.state.fail_transactions_porcentaje}%</span></span>
                </div>


            </div>
        </div>
    )
}

}
export default SimulatorHome