const config = {

    /**
     * BackendClient - Production
     */


    /**
     * BackendClient - Serversite
     */
    url: 'https://akryp-serversite.herokuapp.com'

    /**
     * BackendClient - Localhost - Serversite
     */
    // url: 'http://localhost:8000'

    /**
     * BackendClient - Localhost - Backend
     */
    // url: 'http://localhost:3000'
}

// module.exports = { config }
export default config; 