import React from 'react'
import '../assets/styles/Home.css'

// IMAGENES
import logo_transparente from '../assets/imagenes/akryp_logo.png'

// Aqui mero mandamos a llamar los demas componentes 
class Home extends React.Component {

    render() {

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo_transparente} className="App-logo" alt="logo" />
                <p className="App_subtitle">
                    Invierte en criptomonedas sin tener conocimientos técnicos o cripto
                {/* Invest in crypto without knowledge in tech/crypto */}
                </p>
                <p className="App_extra_text">Prueba nuestro simulador aquí:</p>
                <a href='/Simulator' target="_blank">Simulador</a>
            </header>
        </div>
    )
}

}
export default Home